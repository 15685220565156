// src/components/BalanceDisplay.js

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(() => {
    const theme = useTheme(); // Use theme directly inside makeStyles

    return {
        card: {
            minWidth: 275,
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            color: 'white',
            margin: '20px',
            transition: 'transform .3s',
        },
        textSecondary: {
            fontSize: 14,
            color: '#a9bacc',
        },
        balanceAnimation: {
            transition: 'all 0.3s ease-out'
        }

    }

});

const BalanceDisplay = ({ balance }) => {
    const classes = useStyles();
    const formatBalance = (balance) => {
        return balance.toString().replace(/\.?0+$/, '');
      };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.textSecondary} gutterBottom>
                    Faucet Balance
                </Typography>
                <Typography variant="h5" component="div" className={classes.balanceAnimation}>
                {balance} TCRO
                </Typography>
            </CardContent>
        </Card>
    );
};

export default BalanceDisplay;
