// import footerImg from 'https://thegrandschemeofcrypto.com/images/Logos/white2.png'
import { makeStyles } from '@mui/styles'; // Ensure correct import from '@mui/styles' not '@mui/material/styles'
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(() => {
    const theme = useTheme(); // Use theme directly inside makeStyles
    return {
        footer: {
            textAlign: 'center',
            padding: '20px',
            color: 'white',
            background: 'rgba(0, 0, 0, 0.8)', // Semi-transparent black background
        },
        logo: {
            height: '50px',
            width: '50px',
            animation: '$logoFadeIn 3000ms infinite alternate'
        },
        poweredByText: {
            color: 'white',
            fontSize: '0.8rem',
        },
        '@keyframes logoFadeIn': {
            from: { opacity: 0.5 },
            to: { opacity: 1 }
        }
    }
});
const Footer = () => {

    const classes = useStyles();
    return (
        <div className={classes.footer}>
            <p className={classes.poweredByText}>Powered By</p>
            <img
                className={classes.logo}
                src='https://thegrandschemeofcrypto.com/images/Logos/white2.png'
                alt="Logo"
            />
        </div>
    );
}

export default Footer;