// src/components/WalletConnector.js

import React, {useEffect, useState} from 'react';
import { Button, Typography, Snackbar, Alert, CircularProgress, Tooltip, Avatar } from '@mui/material';
import {makeStyles} from '@mui/styles';
import metamaskImage from '../assets/images/metamask_clear.png'; // Adjust the path as necessary
import contractABI from '../abi.json';
import earlyAccessContractABI from '../EarlyAccessContractABI.json';
import RequestForm from './RequestForm';
import Web3 from 'web3';
import BalanceDisplay from './BalanceDisplay';
import backgroundVideo from '../assets/logo.mp4';
import nftImage from '../assets/Crocondunit.png';
import EarlyAccessList from './EarlyAccessList';
import MintNFT from './MintNFT';
import nftContractABI from '../assets/contracts/nft/abi.json';



const contractAddress = '0x73DC7594ba4DB8981880F80dc7fc547A1d05D7B1';
const nftContractAddress = '0x497e1656Ba47e4b02db5Db241d5d9F13D0D74448';
const requiredNFTId = 1;
const earlyAccessContractAddress = '0xca3Bb830a858f3fD40cc3133A86d471EAED36f1B';





const useStyles = makeStyles(theme => ({
    button: {
        background: 'linear-gradient(45deg, red 30%, #ff8e53 90%)',
        border: 0,
        fontWeight:900,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        transition: 'transform .2s', // smooth transform on hover
    },
    connectButton: {
        background: 'linear-gradient(45deg, green 30%, #ff8e53 90%)',
        border: 0,
        fontWeight:900,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        transition: 'transform .2s', // smooth transform on hover
    },
    buttonContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        display: 'flex',
        alignItems: 'center',

    },
    hoverEffect: {
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    videoBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: -1,
    },
    content: {
        position: 'relative',
        zIndex: 1,
        padding: 4,
        textAlign: 'center',
    },
    nftImage: {
        maxWidth: '200px',
        marginBottom: 4,
    },
    avatar: {
        width: 5,
        height: 5,
        marginBottom: 5,
    },
}));


const WalletConnector = () => {
    const classes = useStyles();
    const [account, setAccount] = useState('');
    const [faucetBalance, setFaucetBalance] = useState(0);
    const [connectionPending, setConnectionPending] = useState(false);
    const [faucetTokenBalance, setFaucetTokenBalance] = useState(0);
    const [isEligible, setIsEligible] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [loading, setLoading] = useState(false);



    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        setFaucetBalance(0)
        const checkEligibility = async () => {
            if (window.ethereum && account) {
                const web3 = new Web3(window.ethereum);
                const myContract = new web3.eth.Contract(contractABI, contractAddress);

                try {
                    const eligible = await myContract.methods.isEligible(account).call();
                    console.log(eligible)
                    setIsEligible(eligible);
                    console.log(isEligible)
                } catch (error) {
                    console.error(error);
                }
            }
        };

        checkEligibility();
        updateFaucetBalances(); // Add this line

    }, [account]);


    async function addUserToEarlyAccessList() {
        if (window.ethereum && account) {
          const web3 = new Web3(window.ethereum);
          const earlyAccessContract = new web3.eth.Contract(earlyAccessContractABI, earlyAccessContractAddress);
    
          try {
            const userAddress = account;
    
            await earlyAccessContract.methods.addToEarlyAccessList().send({ from: userAddress });
            console.log('User added to early access list:', userAddress);
            showSnackbar('Successfully added to early access list', 'success');
          } catch (error) {
            console.error('Error adding user to early access list:', error);
            if (error.message.includes('User is already in the early access list.')) {
              showSnackbar('You are already in the early access list', 'info');
            } else {
              showSnackbar('Error adding to early access list. Please try again.', 'error');
            }
          }
        }
      }

    const updateFaucetBalances = async () => {
        if (window.ethereum && account) {
            const web3 = new Web3(window.ethereum);
            const myContract = new web3.eth.Contract(contractABI, contractAddress);

            try {
                // const croBalance = await myContract.methods.getCROBalance().call();

                const tokenBalance = await myContract.methods.getEtherBalance(contractAddress).call();
                console.warn(tokenBalance)

                setFaucetBalance(web3.utils.fromWei(tokenBalance, 'ether'));
                // setFaucetBalance(tokenBalance);
                console.warn(faucetBalance)
            } catch (error) {
                console.error(error);
            }
        }
    };

        // src/components/WalletConnector.js
    async function disconnectWalletHandler() {
        if (window.ethereum) {
        try {
            setAccount('');
            showSnackbar('Wallet disconnected successfully');
        } catch (error) {
            console.error(error);
            showSnackbar('Error disconnecting wallet');
        }
        }
    }


    async function connectWalletHandler() {
        if (window.ethereum) {
          const web3 = new Web3(window.ethereum);
          try {
            setLoading(true);
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await web3.eth.getAccounts();
            setAccount(accounts[0]);
            console.log("Connected", accounts[0]);
            showSnackbar("Connected Successfully")
            await addUserToEarlyAccessList(); 
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
            if (error.code === 4001) {
              showSnackbar("You cancelled the connection attempt. Please try again.", 'warning');
            } else {
              showSnackbar("An error occurred while connecting to MetaMask. Please try again.", 'error');
            }
          }
        } else {
          showSnackbar("Please install MetaMask!", 'warning');
        }
      }

    useEffect(() => {
        const initWeb3 = async () => {
            if (window.ethereum) {
                window.ethereum.on('accountsChanged', async (accounts) => {
                    if (accounts.length === 0) {
                      setAccount('');
                      showSnackbar("You have been disconnected from MetaMask. Please connect again.", 'warning');
                    } else {
                      setAccount(accounts[0]);
                      console.log("Connected", accounts[0]);
                    }
                  });

                try {
                    const web3 = new Web3(window.ethereum);
                    const accounts = await web3.eth.getAccounts();
                    if (accounts.length > 0) {
                        setAccount(accounts[0]);
                        console.log("Connected", accounts[0]);
                    }
                } catch (error) {
                    console.error(error);
                }


                const web3 = new Web3(window.ethereum);
                try {
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    const accounts = await web3.eth.getAccounts();
                    setAccount(accounts[0]);
                    console.log("Connected", accounts[0]);
                } catch (error) {
                    console.error(error);
                }
            } 
        };
        initWeb3();
        updateFaucetBalances(); // Add this line

    }, []);

    const sendTransaction = async () => {
        if (window.ethereum && account) {
            const web3 = new Web3(window.ethereum);
            const myContract = new web3.eth.Contract(contractABI, contractAddress, {
                from: account // default from address
            });

            try {
                const result = await myContract.methods.depositCRO().send({ from: account, value: web3.utils.toWei(10, "ether") });
                console.log('Transaction Hash:', result.transactionHash);
                updateFaucetBalances(); // Add this line

            } catch (error) {
                console.error(error);
            }
        }
    };

    const withdrawTransaction = async () => {
        if (window.ethereum && account) {
            const web3 = new Web3(window.ethereum);
            const withdrawAmount = 1
            const myContract = new web3.eth.Contract(contractABI, contractAddress, {
                from: account // default from address
            });

            try {
                const amountInWei = web3.utils.toWei(withdrawAmount.toString(), "ether");

                const result = await myContract.methods.withdrawCRO(amountInWei).send({ from: account });
                console.log('Transaction Hash:', result.transactionHash);
                // updateFaucetBalances(); // Add this line

                setFaucetBalance(faucetBalance - withdrawAmount);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleMintSuccess = async () => {
        try {
          // Check if the user owns the required NFT
          const web3 = new Web3(window.ethereum);
          const nftContract = new web3.eth.Contract(nftContractABI, nftContractAddress);
          const balance = await nftContract.methods.balanceOf(account).call();
    
          // Update eligibility status based on NFT ownership
          setIsEligible(balance > 0);
    
          // Refresh faucet balances
          await updateFaucetBalances();
    
          // Show success message
          showSnackbar('NFT minted successfully! You can now use the faucet.', 'success');
        } catch (error) {
          console.error('Error checking NFT ownership:', error);
          showSnackbar('An error occurred while checking NFT ownership. Please try again.', 'error');
        }
      };

    




    return (
        <>
        <Typography style={{color:'white', fontFamily:'Floodwater', marginTop: '50px'}} variant="h1" gutterBottom>
                    Welcome to the CRO Faucet
                </Typography>
                {/* <img src={nftImage} alt="NFT" className={classes.nftImage} /> */}
            <video className={classes.videoBackground} autoPlay loop muted>
                <source src={backgroundVideo} type="video/mp4" />
            </video>
        
        <div className={classes.buttonContainer} style={{ padding: '20px', textAlign: 'center' }}>
        <Tooltip title="Connect your MetaMask wallet">
        <Button className={`${classes.connectButton} ${classes.hoverEffect}`} style={{color:'white',  fontWeight:'bold',}} onClick={connectWalletHandler}>
        {loading ? (
                                <CircularProgress size={24} />
                            ) : account ? (
                                'Connected'
                            ) : (
                                'Connect Wallet'
                            )}
            </Button>
            </Tooltip>
            {account && (
            <Button
          className={`${classes.button} ${classes.hoverEffect}`}
          style={{ color: 'white', marginLeft: '10px', fontWeight: 'bold' }}
          onClick={disconnectWalletHandler}
         >
          Disconnect Wallet
        </Button>
      )}
      
            
        </div>
        
        
        {account && isEligible ? (
                <>

                <MintNFT
                        account={account}
                        nftContractAddress={nftContractAddress}
                        onMintSuccess={handleMintSuccess}
                        />
                    
                    <BalanceDisplay balance={faucetBalance} tokenBalance={faucetTokenBalance} ></BalanceDisplay>
                    <RequestForm onRequest={sendTransaction} onDeposit={withdrawTransaction} />
                </>
            ) : (
                <div>
                     <img src={metamaskImage} alt="Only MetaMask Supported" style={{ maxWidth: '100px', marginBottom: '20px' }} />
                <p style={{color:'white'}}>Please connect your wallet to use the faucet & you need to be an owner of a Cro Crook NFT to use the faucet.</p>
                </div>
               
            )}

<Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
                
            </Snackbar>
            {account && (
        <EarlyAccessList earlyAccessContractAddress={earlyAccessContractAddress} />
      )}




        </>
        
    );
};

export default WalletConnector;
