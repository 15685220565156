// src/components/EarlyAccessList.js
import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Web3 from 'web3';
import earlyAccessContractABI from '../EarlyAccessContractABI.json';
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(() => {
    const theme = useTheme(); // Use theme directly inside makeStyles
return {
    listItem: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        marginBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      },
      button: {
        marginTop: '10px',
        background: 'linear-gradient(45deg, gold 30%, black 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        
      },
};
});

const EarlyAccessList = ({ earlyAccessContractAddress }) => {
  const classes = useStyles();
  const [earlyAccessUsers, setEarlyAccessUsers] = useState([]);
  const [showList, setShowList] = useState(false);


  useEffect(() => {
    const fetchEarlyAccessUsers = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        const earlyAccessContract = new web3.eth.Contract(earlyAccessContractABI, earlyAccessContractAddress);

        try {
          const users = await earlyAccessContract.methods.getEarlyAccessUsers().call();
          setEarlyAccessUsers(users);
        } catch (error) {
          console.error('Error fetching early access users:', error);
        }
      }
    };

    fetchEarlyAccessUsers();
  }, [earlyAccessContractAddress]);

  const obfuscateAddress = (address) => {
    const firstPart = address.slice(0, 6);
    const lastPart = address.slice(-4);
    return `${firstPart}...${lastPart}`;
  };

  const toggleShowList = () => {
    setShowList(!showList);
  };

  return (
    <div>
      <Typography variant="h6" style={{ color: 'white', marginBottom: '16px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontWeight: 'bold', }}>
        Early Access Users:
      </Typography>
      {showList ? (
        <List>
          {earlyAccessUsers.map((address, index) => (
            <ListItem key={index} className={classes.listItem}>
              <ListItemText primary={obfuscateAddress(address)} style={{ color: 'white' }} />
            </ListItem>
          ))}
        </List>
      ) : null}
      <Button style={{fontWeight: 'bold', color: 'white'}} className={classes.button} onClick={toggleShowList}>
        {showList ? 'Hide List' : 'Show List'}
      </Button>
    </div>
  );
};

export default EarlyAccessList;