// RoadmapCompletion.js
import React, { useState } from 'react';
import '../styles/RoadmapCompletion.css';

const RoadmapCompletion = () => {
  const [state, setState] = useState(0);
  const stateMax = 4;

  const handleNext = () => {
    if (state < stateMax) {
      setState(prevState => prevState + 1);
    }
  };

  const handleBack = () => {
    if (state > 0) {
      setState(prevState => prevState - 1);
    }
  };

  return (
    <div className="roadmapWrapper">
      <div className="mainWrapper">
        <h1 style={{color:'white',fontFamily:'Floodwater'}}>Status Completion</h1>
        <div className="statusBar">
          <span className="pBar" style={{ width: `${(state / stateMax) * 100}%` }}></span>
          <div className={`node n0 ${state >= 0 ? 'done' : ''} nConfirm0`}>
            <div className={`main ${state >= 0 ? 'done' : ''} m0 nConfirm0`}></div>
            <span className={`text t0 ${state >= 0 ? 'done' : ''} nConfirm0`}>Inception</span>
          </div>
          <div className={`node n1 ${state >= 1 ? 'done' : ''} nConfirm1`}>
            <div className={`main ${state >= 1 ? 'done' : ''} m1 nConfirm1`}></div>
            <span className={`text t1 ${state >= 1 ? 'done' : ''} nConfirm1`}>Pre-Launch (Testnet)</span>
          </div>
          <div className={`node n2 ${state >= 2 ? 'done' : ''} nConfirm2`}>
            <div className={`main ${state >= 2 ? 'done' : ''} m2 nConfirm2`}></div>
            <span className={`text t2 ${state >= 2 ? 'done' : ''} nConfirm2`}>Beta Launch (Mainnet)</span>
          </div>
          <div className={`node n3 ${state >= 3 ? 'done' : ''} nConfirm3`}>
            <div className={`main ${state >= 3 ? 'done' : ''} m3 nConfirm3`}></div>
            <span className={`text t3 ${state >= 3 ? 'done' : ''} nConfirm3`}>Official Launch (Mainnet)</span>
          </div>
          <div className={`node n4 ${state >= 4 ? 'done' : ''} nConfirm4`}>
            <div className={`main ${state >= 4 ? 'done' : ''} m4 nConfirm4`}></div>
            <span className={`text t4 ${state >= 4 ? 'done' : ''} nConfirm4`}>Complete</span>
          </div>
        </div>
        {/* Uncomment these buttons if you want to control the status/roadmap manually */}
        {/* <div className="buttonHolder">
          <div
            className={`button b-back ${state === 0 ? 'disabled' : ''}`}
            onClick={handleBack}
          >
            Back
          </div>
          <div
            className={`button b-next ${state === stateMax ? 'disabled' : ''}`}
            onClick={handleNext}
          >
            Next
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RoadmapCompletion;