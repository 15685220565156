// src/components/ExternalLinks.js
import React from 'react';
import { Link, Typography, IconButton } from '@mui/material';
import {linkData } from '../data/linkData';
import { useTheme } from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import { Twitter, Chat, ShoppingCart } from '@mui/icons-material';



const useStyles = makeStyles(() => {
  const theme = useTheme(); // Use theme directly inside makeStyles
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    iconButton: {
      color: 'white',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }
})


const ExternalLinks = ({ links }) => {
  const classes = useStyles();

  const getIcon = (title) => {
    switch (title) {
      case 'Purchase':
        return <ShoppingCart />;
      case 'Chat':
        return <Chat />;
      case 'Updates':
        return <Twitter />;
      default:
        return null;
    }
  };

  return (
    <div>
      {linkData.map((link, index) => (
        <IconButton
        key={index}
        className={classes.iconButton}
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {getIcon(link.title)}
      </IconButton>
      ))}
    </div>
  );
};

export default ExternalLinks;