// src/components/RequestForm.js

import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import {makeStyles} from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => {
    const theme = useTheme(); // Use theme directly inside makeStyles
return {
    form: {
        padding: '20px',
        textAlign: 'center', 
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            background: 'white',
            backdropFilter:'blur(10px',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            color: 'white',
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        background: 'red',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 255, .3)',
        transition: '0.4s', 
        '&:hover': {
            background: 'linear-gradient(to right, #2575fc 0%, #6a11cb 100%)',
            boxShadow: '0 4px 7px 2px rgba(0, 0, 255, .5)',
            transform: 'scale(1.1)',
        },

    },
    depositButton: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        background: 'green',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 255, .3)',
        transition: '0.4s', 
        '&:hover': {
            background: 'linear-gradient(to right, #2575fc 0%, #6a11cb 100%)',
            boxShadow: '0 4px 7px 2px rgba(0, 0, 255, .5)',
            transform: 'scale(1.1)',
        },

    }
};
});


const RequestForm = ({ onRequest, onDeposit }) => {
    const [amount, setAmount] = useState('');
    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
        onRequest(amount);
    };

    const handleWithdraw = (e) => {
        e.preventDefault();
        onDeposit();
    }

    return (
        <Box
            component="form"
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            {/* <div>
                <TextField
                    required
                    id="outlined-required"
                    label="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    InputLabelProps={{
                        style: { color: '#a9bacc' }
                    }}
                />
            </div> */}
            <Button type="submit" style={{fontWeight:'bold', backgroundColor: 'green', color:'white', padding: '10px', margin: '10px',  marginRight: '10px', '&:hover': {
            background: 'linear-gradient(to right, #2575fc 0%, #6a11cb 100%)',
            boxShadow: '0 4px 7px 2px rgba(0, 0, 255, .5)',
            transform: 'scale(1.1)', 


        },}}  className={classes.depositButton}>
                Deposit Funds
            </Button>
            <Button  style={{fontWeight:'bold', backgroundColor: 'red', color:'white', '&:hover': {
            background: 'linear-gradient(to right, #2575fc 0%, #6a11cb 100%)',
            boxShadow: '0 4px 7px 2px rgba(0, 0, 255, .5)',
            transform: 'scale(1.1)',


        },}}  onClick={handleWithdraw}  className={classes.button}>
               Withdraw Funds
            </Button>
            
        </Box>
    );
};

export default RequestForm;
