// src/components/MintNFT.js
import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Web3 from 'web3';
import nftContractABI from '../assets/contracts/nft/abi.json';
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(() => {
    const theme = useTheme(); // Use theme directly inside makeStyles

    return {
        button: {
            background: 'linear-gradient(45deg, gold 30%, black 90%)',
            border: 0,
            borderRadius: 3,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            color: 'white',
            height: 48,
            padding: '0 30px',
            marginTop: theme.spacing(2),
        },
       
    }
});

const MintNFT = ({ account, nftContractAddress, onMintSuccess }) => {
    const classes = useStyles();
    const [minting, setMinting] = useState(false);
  
    const mintNFT = async () => {
      if (window.ethereum && account) {
        const web3 = new Web3(window.ethereum);
        const nftContract = new web3.eth.Contract(nftContractABI, nftContractAddress);
  
        try {
          setMinting(true);
          await nftContract.methods.safeMint(account).send({ from: account });
          onMintSuccess();
          setMinting(false);
        } catch (error) {
          console.error('Error minting NFT:', error);
          setMinting(false);
        }
      }
    };
  
    return (
      <div>
        <Typography variant="h6" style={{ color: 'white', fontWeight: 'bold', marginTop: '16px' }}>
          Mint an NFT to use the faucet:
        </Typography>
        <Button className={classes.button} style={{fontWeight: 'bold', color:'white'}} onClick={mintNFT} disabled={minting}>
          {minting ? 'Minting...' : 'Mint NFT'}
        </Button>
      </div>
    );
  };
  
  export default MintNFT;