import logo from './logo.svg';
import './App.css';
import './styles/font.css';

import WalletConnector from './components/WalletConnector';
import BalanceDisplay from './components/BalanceDisplay';
import RequestForm from './components/RequestForm';
import Footer from './components/Footer';
import RoadmapCompletion from './components/RoadmapCompletion';
import ExternalLinks from './components/ExternalLinks';


function App() {
  return (
    <div className="App">
      <WalletConnector></WalletConnector>
      <RoadmapCompletion></RoadmapCompletion>
      <Footer></Footer>
      <ExternalLinks></ExternalLinks>
    </div>
  );
}

export default App;
