// src/data/linkData.js
export const linkData = [
    {
      title: 'Purchase',
      url: 'https://discord.com/example',
    },
    {
      title: 'Chat',
      url: 'https://chat.example.com',
    },
    {
      title: 'Updates',
      url: 'https://x.com/crocryptoclub',
    },
    // Add more link objects as needed
  ];